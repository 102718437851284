import { call, put, select, takeLatest } from "redux-saga/effects";
import { AuthRepository } from "../repository";
import Cookies from 'js-cookie';
import { navigateToSignInAction } from "../navigation";
import { handleApiError } from "../utils";
import authSlice from "./Slice";
import patientFilesSlice from "../patientFiles/Slice";
import brandSlice from "../brands/Slice";
import { mapAzureInfo, mapMemberId, mapSignInResponse, mapSignUpResponse, mapToken, } from "./Mapper";
import navigationSlice from "../navigation/Slice";
import ApiManager from "../api/ApiManager";
import accountSlice from "../account/Slice";
import { CompleteRegistrationPayloadSelector, memberInfoSelector, passwordSelector, phoneNumberSelector, signUpPayloadSelector, } from "./selectors";
import { getHomeSaga, getRegistrationInfoSaga } from "../account/Sagas";
import { changePasswordAsyncAction, changePhoneNumberAsyncAction, changePhoneRequestAsyncAction, isRegisteredUserAsyncAction, requestSmsCodeAsyncAction, signInAsyncAction, signUpAsyncAction, validatePasswordAsyncAction, validateSmsCodeAsyncAction, addMemberAsyncAction, signUpForExistingUserAsyncAction, verifyEmailAsyncAction, logOutAsyncAction, refreshTokenAsyncAction } from "./actions";
import { resetMemberDrugBrandCodeAction } from "../account";
import { setPhoneNumberAction } from ".";
import { getAnalytics, logEvent, setUserId } from "@firebase/analytics";
import moment from "moment";
import config from "../../../config.json";
import { getMessaging, getToken } from "firebase/messaging";
export function* getFcmToken() {
    console.log('Requesting permission...');
    let permission = "default";
    Notification.requestPermission().then(x => {
        permission = x;
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            const messaging = getMessaging();
            getToken(messaging, { vapidKey: "BBYUcIE3TEJRbp3PMl9DITTZ12HfIvAeXNq1Q1YStruP9OuHGcwBlMNKkUUDoEo2O7jVygxjuyX5vkoPesj12cg" })
                .then(fcmToken => {
                if (fcmToken) {
                    return fcmToken;
                }
            }).catch(e => {
                console.log('No registration token available. Request permission to generate one.', e);
            });
        }
    }).catch(e => {
        console.log(e);
    });
}
export function* signInSaga(action) {
    const { startRequestSignInAction, successRequestSignInAction, showErrorSignInAction, setNeedsTwoFactorAction, storeDataLoginResponseActions, storeDataForStepToComplete } = authSlice.actions;
    const { setUserAction } = accountSlice.actions;
    const { setStepsAction } = navigationSlice.actions;
    try {
        yield put(startRequestSignInAction());
        const result = yield call(AuthRepository.signIn, action.payload.identifier, action.payload.password, action.payload.signInType, action.payload.twoFactorCode);
        const token = yield mapToken(result.data);
        if (result.data.value.twoFactorRequired) {
            yield put(setNeedsTwoFactorAction(true));
            return;
        }
        Cookies.set("token", token, { expires: 1 / 2 });
        ApiManager.getInstance().setTokenFromCookie();
        // ApiManager.getInstance().setToken(token);
        const azureInfo = yield mapAzureInfo(result.data);
        yield put(setPhoneNumberAction(azureInfo.userPhoneNumber || ""));
        yield put(storeDataForStepToComplete(result.data.value?.selectedPatientInfo?.stepsToComplete));
        let data = yield mapSignInResponse(result.data);
        // data.stepsToComplete = data?.stepsToComplete?.filter((val, index) => {
        //   return config?.additional_steps
        //     .some(additionSteps => additionSteps?.step === val?.step && additionSteps?.show);
        // });
        yield put(setStepsAction({
            steps: data?.stepsToComplete ?? [],
            isRegistrationFlow: false,
        }));
        if (!result?.data?.value?.createAccount) {
            yield put(setUserAction({
                user: {
                    drugBrandCode: data.currentDrugBrandCode,
                    patientId: data.patientId,
                    phoneNumber: azureInfo?.userPhoneNumber || action.payload.identifier,
                    province: data.patientProvince,
                    password: action.payload.password,
                },
                drugBrandsCodes: [],
                brandPhoneNumber: data.currentBrandPhoneNumber,
                brandEmail: data.currentBrandEmail,
                registrationUnfinished: data.registrationUnfinished,
                currentProgramLogo: data.currentProgramLogo,
                currentProgramWebsite: data.currentProgramWebsite,
                currentDrugBrandPrescriberName: data.currentDrugBrandPrescriberName,
            }));
        }
        const fcmToken = yield getFcmToken();
        if (fcmToken && !result.data?.value?.createAccount) {
            yield call(AuthRepository.subscribeDevice, fcmToken);
        }
        setUserId(getAnalytics(), azureInfo.azureUserId);
        logEvent(getAnalytics(), "login", {
            userId: azureInfo.azureUserId,
            phoneNumber: azureInfo?.userPhoneNumber,
            userEmail: azureInfo?.userEmail,
        });
        yield put(storeDataLoginResponseActions(result));
        yield put(successRequestSignInAction({
            ...azureInfo,
            password: action.payload.password
        }));
    }
    catch (error) {
        yield handleApiError(error, showErrorSignInAction);
    }
}
export function* logOutSaga() {
    const { startRequestLogOutAction, successRequestLogOutAction, showLogOutErrorAction, resetRequestLogOutAction, } = authSlice.actions;
    const { resetAdditionalStepsSliceAction } = patientFilesSlice.actions;
    const { resetBrandsSliceAction } = brandSlice.actions;
    try {
        yield put(startRequestLogOutAction());
        try {
            const fcmToken = yield getFcmToken();
            if (fcmToken) {
                yield call(AuthRepository.signOut, fcmToken);
                //api sign out is for firebase push notifications
            }
        }
        catch (e) {
            console.log(e);
        }
        yield put(successRequestLogOutAction());
        yield put(resetAdditionalStepsSliceAction());
        yield put(resetBrandsSliceAction());
        yield put(resetRequestLogOutAction());
        yield put(navigateToSignInAction());
    }
    catch (error) {
        yield handleApiError(error, showLogOutErrorAction);
    }
}
export function* refreshTokenSaga() {
    const { startRequestRefreshTokenAction, successRequestRefreshTokenAction, showRefreshTokenErrorAction, resetRequestRefreshTokenAction, } = authSlice.actions;
    try {
        yield put(startRequestRefreshTokenAction());
        const result = yield call(AuthRepository.refreshToken);
        const token = result.data.value.accessToken;
        // TODO: use jwt-decode to get expiry and map to cookie expiry 
        Cookies.set("token", token, { expires: 1 / 2 });
        ApiManager.getInstance().setTokenFromCookie();
        yield put(successRequestRefreshTokenAction());
        // yield put(resetRequestRefreshTokenAction());
    }
    catch (error) {
        yield handleApiError(error, showRefreshTokenErrorAction);
    }
}
export function* isRegisteredUserSaga(action) {
    const { startRequestIsRegisteredAction, showRequestIsRegisteredErrorAction, successRequestIsRegisteredAction, } = authSlice.actions;
    try {
        yield put(startRequestIsRegisteredAction());
        yield call(AuthRepository.isRegisteredUser, action.payload);
        yield put(successRequestIsRegisteredAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestIsRegisteredErrorAction);
    }
}
export function* requestSmsCodeSaga() {
    const { startRequestSmsCodeAction, showRequestSmsCodeErrorAction, successRequestSmsCodeAction, } = authSlice.actions;
    try {
        yield put(startRequestSmsCodeAction());
        const number = yield select(phoneNumberSelector);
        yield call(AuthRepository.sendVerificationCode, number, config.applicationType);
        yield put(successRequestSmsCodeAction());
    }
    catch (error) {
        yield handleApiError(error, showRequestSmsCodeErrorAction);
    }
}
export function* validateSmsCodeSaga(action) {
    const { startRequestValidateCodeAction, showRequestValidateCodeErrorAction, successRequestValidateCodeAction, } = authSlice.actions;
    try {
        yield put(startRequestValidateCodeAction());
        const number = yield select(phoneNumberSelector);
        yield call(AuthRepository.validateVerificationCode, action.payload, number);
        yield put(successRequestValidateCodeAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestValidateCodeErrorAction);
    }
}
export function* signUpForExistAzureUserSaga(action) {
    const { startRequestSignUpAction, showRequestSignUpErrorAction, successRequestSignUpAction, } = authSlice.actions;
    const { setUserAction } = accountSlice.actions;
    try {
        yield put(startRequestSignUpAction());
        const payload = yield select(CompleteRegistrationPayloadSelector);
        const phoneNumber = yield select(phoneNumberSelector);
        const result = yield call(AuthRepository.signUpForExistingAzureUser, payload);
        const mapped = yield mapSignUpResponse(result.data);
        yield put(setUserAction({
            user: {
                drugBrandCode: payload.userDetailsStepDto.drugBrandCode,
                patientId: mapped.patientId,
                phoneNumber: phoneNumber,
                province: payload.almostDoneStepDto.province,
            },
            drugBrandsCodes: [],
            brandPhoneNumber: "IDACIO",
            // brandPhoneNumber: mapped.currentBrandPhoneNumber,
            brandEmail: "",
            currentProgramLogo: undefined,
            currentProgramWebsite: null,
            currentDrugBrandPrescriberName: undefined,
        }));
        yield put(successRequestSignUpAction());
        const fcmToken = yield getFcmToken();
        if (fcmToken && !result.data?.value?.createAccount) {
            yield call(AuthRepository.subscribeDevice, fcmToken);
        }
        logEvent(getAnalytics(), "sign_up_azure", {
            patientId: mapped.patientId,
            phoneNumber: phoneNumber,
            gender: payload.almostDoneStepDto.gender,
            age: moment().diff(payload.patientDetailsStepDto?.birthDate, 'years'),
        });
        logEvent(getAnalytics(), "condition_selection", {
            patientId: mapped.patientId,
            conditions: payload.almostDoneStepDto.medicalConditions,
        });
        Cookies.set("token", mapped.token, { expires: 1 / 2 });
        ApiManager.getInstance().setTokenFromCookie();
    }
    catch (error) {
        yield handleApiError(error, showRequestSignUpErrorAction);
    }
}
export function* signUpSaga(action) {
    const { startRequestSignUpAction, showRequestSignUpErrorAction, successRequestSignUpAction, } = authSlice.actions;
    const { setUserAction } = accountSlice.actions;
    try {
        yield put(startRequestSignUpAction());
        const payload = yield select(signUpPayloadSelector, action.payload);
        const result = yield call(AuthRepository.signUp, payload);
        const mapped = yield mapSignUpResponse(result.data);
        yield put(setUserAction({
            user: {
                drugBrandCode: payload.userDetailsStepDto.drugBrandCode,
                patientId: mapped.patientId,
                phoneNumber: payload.getStartedStepDto.phoneNumber,
                province: payload.almostDoneStepDto.province,
            },
            drugBrandsCodes: [],
            brandPhoneNumber: "IDACIO",
            // brandPhoneNumber: mapped.currentBrandPhoneNumber,
            brandEmail: "",
            currentProgramLogo: undefined,
            currentProgramWebsite: null,
            currentDrugBrandPrescriberName: undefined,
        }));
        yield put(successRequestSignUpAction());
        Cookies.set("token", mapped.token, { expires: 1 / 2 });
        const fcmToken = yield getFcmToken();
        if (fcmToken && !result.data?.value?.createAccount) {
            yield call(AuthRepository.subscribeDevice, fcmToken);
        }
        logEvent(getAnalytics(), "sign_up", {
            firebase_screen: "SignUp",
            firebase_screen_class: "SignUp",
            patientId: mapped.patientId,
            phoneNumber: payload.getStartedStepDto.phoneNumber,
            gender: payload.almostDoneStepDto.gender,
            age: moment().diff(payload.patientDetailsStepDto?.birthDate, 'years'),
        });
        logEvent(getAnalytics(), "condition_selection", {
            patientId: mapped.patientId,
            conditions: payload.almostDoneStepDto.medicalConditions,
        });
        ApiManager.getInstance().setTokenFromCookie();
    }
    catch (error) {
        yield handleApiError(error, showRequestSignUpErrorAction);
    }
}
export function* changePhoneRequestSaga(action) {
    const { startRequestChangePhoneCheckAction, successRequestChangePhoneCheckAction, showChangePhoneCheckErrorAction, } = authSlice.actions;
    try {
        yield put(startRequestChangePhoneCheckAction());
        yield call(AuthRepository.changePhoneRequest, action.payload);
        yield put(successRequestChangePhoneCheckAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showChangePhoneCheckErrorAction);
    }
}
export function* changePhoneNumberSaga(action) {
    const { startRequestChangePhoneAction, successRequestChangePhoneAction, showChangePhoneErrorAction, } = authSlice.actions;
    try {
        yield put(startRequestChangePhoneAction());
        yield call(AuthRepository.changePhoneNumber, action.payload);
        yield put(successRequestChangePhoneAction());
    }
    catch (error) {
        yield handleApiError(error, showChangePhoneErrorAction);
    }
}
export function* validatePasswordSaga(action) {
    const { startRequestValidatePasswordAction, successRequestValidatePasswordAction, showValidatePasswordErrorAction, } = authSlice.actions;
    try {
        yield put(startRequestValidatePasswordAction());
        yield call(AuthRepository.validatePassword, action.payload);
        yield put(successRequestValidatePasswordAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showValidatePasswordErrorAction);
    }
}
export function* changePasswordSaga(action) {
    const { startRequestChangePasswordAction, successRequestChangePasswordAction, showChangePasswordErrorAction, } = authSlice.actions;
    const oldPassword = yield select(passwordSelector);
    try {
        yield put(startRequestChangePasswordAction());
        yield call(AuthRepository.changePassword, {
            oldPassword,
            newPassword: action.payload,
            newPasswordConfirmation: action.payload,
        });
        yield put(successRequestChangePasswordAction());
    }
    catch (error) {
        yield handleApiError(error, showChangePasswordErrorAction);
    }
}
export function* addMemberSaga(action) {
    const { startRequestAddMemberAction, successRequestAddMemberAction, showRequestAddMemberErrorAction, } = authSlice.actions;
    const { setMemberIdAction } = accountSlice.actions;
    try {
        yield put(startRequestAddMemberAction());
        const payload = yield select(memberInfoSelector, action.payload);
        const result = yield call(AuthRepository.addMember, payload);
        const memberId = yield mapMemberId(result.data);
        yield put(setMemberIdAction(memberId));
        yield getHomeSaga();
        yield getRegistrationInfoSaga({
            payload: {
                member: {
                    patientId: memberId,
                    drugBrandCode: payload.drugBrandCode,
                },
                isRegistrationFlow: false,
            },
            type: "ACCOUNT/getRegistrationInfoAsyncAction",
        });
        logEvent(getAnalytics(), "condition_selection", {
            patientId: memberId,
            conditions: payload.medicalConditions,
        });
        yield put(successRequestAddMemberAction());
        yield put(resetMemberDrugBrandCodeAction());
    }
    catch (error) {
        yield handleApiError(error, showRequestAddMemberErrorAction);
    }
}
export function* verifyEmailSaga(action) {
    const { startRequestValidateEmailAction, successRequestValidateEmailAction, showRequestValidateEmailErrorAction, } = authSlice.actions;
    try {
        yield put(startRequestValidateEmailAction());
        const result = yield call(AuthRepository.verifyEmail, action.payload);
        yield put(successRequestValidateEmailAction());
    }
    catch (error) {
        yield handleApiError(error, showRequestValidateEmailErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(isRegisteredUserAsyncAction, isRegisteredUserSaga);
    yield takeLatest(requestSmsCodeAsyncAction, requestSmsCodeSaga);
    yield takeLatest(validateSmsCodeAsyncAction, validateSmsCodeSaga);
    yield takeLatest(signUpAsyncAction, signUpSaga);
    yield takeLatest(signUpForExistingUserAsyncAction, signUpForExistAzureUserSaga);
    yield takeLatest(signInAsyncAction, signInSaga);
    yield takeLatest(logOutAsyncAction, logOutSaga);
    yield takeLatest(refreshTokenAsyncAction, refreshTokenSaga);
    yield takeLatest(changePhoneRequestAsyncAction, changePhoneRequestSaga);
    yield takeLatest(changePhoneNumberAsyncAction, changePhoneNumberSaga);
    yield takeLatest(validatePasswordAsyncAction, validatePasswordSaga);
    yield takeLatest(changePasswordAsyncAction, changePasswordSaga);
    yield takeLatest(addMemberAsyncAction, addMemberSaga);
    yield takeLatest(verifyEmailAsyncAction, verifyEmailSaga);
}
